import { Dimensions, StyleSheet } from 'react-native';
import Colors from './Colors';

function getDeviceSize(): string {
  let width = Dimensions.get('window').width;

  if (width > 1200) return 'l';
  else if (width > 992) return 'm';
  else return 's';
}

const deviceSize = getDeviceSize();

const CommonStyleSheet = StyleSheet.create({
  mainContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
  },
  widgetContainer: {
    backgroundColor: Colors.darkestGreen,
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 240,
    maxWidth: 300,
    paddingHorizontal: 12,
    paddingTop: 24,
    paddingBottom: 12,
  },
  controlLabel: {
    fontFamily: 'Poppins',
    fontSize: 28,
    fontWeight: '700',
    color: Colors.darkestGreen,
    paddingTop: 10,
  },
  widget: {
    flexDirection: 'row',
    gap: 24,
    alignItems: 'center',
    width: '100%',
    borderRadius: 6,
    paddingHorizontal: 16,
    paddingVertical: 8,
    marginTop: 12,
  },
  widgetActive: {
    color: Colors.teal,
  },
  widgetText: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: 2.04,
    paddingTop: 2,
  },
  bodyContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  // controlLabel: {
  //   fontFamily: 'Poppins',
  //   fontSize: 28,
  //   fontWeight: '700',
  //   color: Colors.darkestGreen,
  //   paddingTop: 10,
  // },
  searchContainer: {
    flexDirection: 'row',
    gap: 14,
    paddingHorizontal: 20,
    marginTop: 20,
  },
  searchBoxContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    minHeight: 40,
    borderWidth: 1,
    borderColor: Colors.darkGreen,
    borderRadius: 40,
    paddingHorizontal: 46,
  },
  searchBoxIcon: {
    position: 'absolute',
    left: 10,
    top: 8,
  },
  searchBoxInput: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    outlineStyle: 'none',
  },
  searchBoxClear: {
    position: 'absolute',
    right: 12,
    top: 11,
  },
  searchFilters: {
    minHeight: 40,
    borderWidth: 1,
    borderColor: Colors.green,
    borderRadius: 40,
  },
  searchFiltersContent: {
    flexDirection: 'row',
    gap: 12,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingHorizontal: 12,
  },
  searchFiltersText: {
    color: Colors.green,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 14,
  },
  searchFiltersIcon: {
    display: 'flex',
    color: Colors.green,
  },
  searchFiltersIconDark: {
    display: 'flex',
    color: Colors.white,
  },
  searchFiltersModal: {
    backgroundColor: Colors.white,
    flex: 1,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginTop: 48,
    paddingHorizontal: 20,
  },
  statusFilterContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 12,
  },
  statusFilterPill: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderRadius: 24,
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  statusFilterText: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  statusFilterCheckBox: {
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: Colors.darkestGreen,
    borderRadius: 22,
    backgroundColor: Colors.white,
  },
  statusFilterCheckBoxIcon: {
    display: 'flex',
    color: Colors.white,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  inputError: {
    color: Colors.red,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 0.5,
    marginTop: 4,
  },
  greenButton: {
    backgroundColor: Colors.green,
    width: 'auto',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 12,
    marginTop: 5,
  },
  greenButtonText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '700',
    color: Colors.white,
  },
  smallGreenButton: {
    backgroundColor: Colors.green,
    width: 'auto',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginTop: 20,
  },
  smallGreenButtonText: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '700',
    color: Colors.white,
  },
  whiteButton: {
    backgroundColor: Colors.white,
    width: 'auto',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 2,
    borderColor: Colors.green,
    paddingHorizontal: 24,
    paddingVertical: 12,
    marginTop: 5,
  },
  whiteButtonText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '700',
    color: Colors.green,
  },
  profileIcon: {
    width: 70,
    height: 70,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderColor: Colors.green,
    borderWidth: 1,
    borderRadius: 70,
    overflow: 'hidden',
    marginBottom: 12,
  },
  profileIconText: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 36,
    fontWeight: '700',
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginBottom: 4,
  },
  profileEditButton: {
    position: 'absolute',
    top: 100,
    left: 100,
    padding: 6,
    borderRadius: 38,
    backgroundColor: Colors.teal,
  },
  profileName: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 42,
    fontWeight: '700',
  },
  profileEmail: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: '700',
    textTransform: 'lowercase',
  },
  profilePhone: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: '700',
    marginTop: 6,
  },
  profileOrganisation: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'capitalize',
    marginTop: 6,
    marginBottom: 24,
  },
  profileQRCode: {
    alignSelf: 'center',
    borderColor: Colors.white,
    borderRadius: 8,
    borderWidth: 8,
    marginBottom: 12,
  },
  // screenTitle: {
  //   color: Colors.white,
  //   fontFamily: 'Poppins',
  //   fontSize: 40,
  //   fontWeight: '700',
  //   marginHorizontal: 20,
  //   marginTop: Dimensions.get('window').height / 5,
  //   marginBottom: 32,
  // },
  // screenBody: {
  //   backgroundColor: Colors.white,
  // },
  screenButtonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 12,
    rowGap: 6,
    padding: 20,
  },
  screenButton: {
    flex: 1,
    gap: 12,
    minHeight: 100,
    backgroundColor: Colors.darkGreenTransparent,
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: 12,
    paddingTop: 18,
    padding: 12,
  },
  screenButtonText: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 2,
    color: Colors.green,
    textTransform: 'uppercase',
  },
  screenLargeButton: {
    flexBasis: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 58,
    backgroundColor: Colors.darkGreenTransparent,
    overflow: 'hidden',
    borderRadius: 12,
    paddingHorizontal: 20,
  },
  toolTip: {
    zIndex: 1000,
    position: 'absolute',
    top: -60,
    width: 'auto',
    backgroundColor: Colors.green,
    borderRadius: 8,
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: '700',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 12,
  },
  // list: {
  //   paddingHorizontal: 20,
  //   paddingTop: 34,
  //   paddingBottom: 20,
  // },
  // listTitle: {
  //   color: Colors.darkestGreen,
  //   fontFamily: 'Poppins',
  //   fontSize: 12,
  //   fontWeight: '700',
  //   letterSpacing: 2,
  //   textTransform: 'uppercase',
  //   alignSelf: 'center',
  //   marginHorizontal: 20,
  //   marginBottom: 6,
  // },
  bcsfFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    marginVertical: 20,
  },
  bcsfFooterImage: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
  bcsfFooterText: {
    fontSize: 20,
    color: 'black',
    paddingBottom: 4,
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#d3d3d3',
    borderBottomWidth: 1,
    borderColor: 'darkgray',
    borderStyle: 'solid',
  },
  screenMessageContainer: {
    backgroundColor: Colors.darkGreenTransparent,
    borderRadius: 12,
    padding: 32,
    marginHorizontal: 20,
  },
  screenMessageText: {
    fontFamily: 'Poppins',
    fontSize: 36,
    fontWeight: '700',
    lineHeight: 37,
    color: Colors.darkestGreen,
  },
  filterButtonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  filterButtonItem: {
    alignItems: 'center',
    minWidth: 68,
  },
  filterButton: {
    backgroundColor: Colors.white,
    width: 48,
    height: 48,
    borderRadius: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterButtonText: {
    color: Colors.darkestGreen,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  grid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flexBasis: deviceSize == 'l' ? '48%' : '100%',
  },
});

export { CommonStyleSheet };
